<template>
  <div>
    初始化数据库
  </div>
</template>

<script>
export default {
  name: "SysInit"
}
</script>

<style scoped>

</style>