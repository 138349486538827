<template>
  <div>
    <div>
      <el-input
          size="small"
          class="addPosInput"
          placeholder="职位名称"
          suffix-icon="el-icon-plus"
          @keydown.enter.native="addPosition"
          v-model="pos.name">
      </el-input>
      <el-button size="small" icon="el-icon-plus" type="primary" @click="addPosition">添加</el-button>
    </div>
    <div class="posManageMain">
      <el-table
          border
          stripe
          size="small"
          :data="positions"
          style="width: 70%"
          @selection-change="handleSelectionChange">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column
            prop="id"
            label="编号"
            width="55">
        </el-table-column>
        <el-table-column
            prop="name"
            label="职位名称"
            width="120">
        </el-table-column>
        <el-table-column
            prop="createDate"
            label="创建时间"
            width="150">
        </el-table-column>
        <el-table-column
            prop="enabled"
            label="是否启用"
            width="100">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.enabled">已启用</el-tag>
            <el-tag type="danger" v-else>未启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="showEditView(scope.$index, scope.row)">编辑
            </el-button>
            <el-button
                size="mini"
                type="danger"
                @click="deletePosition(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- :disabled="this.multipleSelection.length===0" 当复选框没有一个被选中时隐藏 -->
    <el-button size="small" style="margin-top: 8px" type="danger"
               :disabled="this.multipleSelection.length===0"
               @click="batchDeletePosition">批量删除
    </el-button>
    <!-- 编辑弹框 -->
    <el-dialog
        title="编辑职位"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="closeEditView">
      <table>
        <tr>
          <td>
            <el-tag>职位名称</el-tag>
          </td>
          <td>
            <el-input v-model="updatePos.name" size="small" style="margin-left: 6px"></el-input>
          </td>
        </tr>
        <tr>
          <td>
            <el-tag>是否启用</el-tag>
          </td>
          <td>
            <el-switch
                style="margin-left: 6px"
                v-model="updatePos.enabled"
                active-color="#13ce66"
                active-text="启用"
                inactive-text="未启用">
            </el-switch>
          </td>
        </tr>
      </table>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="updatePosition">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "PositionManage",
  data() {
    return {
      // 添加职位数据
      pos: {
        name: ''
      },
      // 更新职位数据
      updatePos: {
        name: '',
        enabled: false
      },
      positions: [],
      // 弹出框是否显示
      dialogVisible: false,
      // 批量删除勾选的对象
      multipleSelection: []
    }
  },
  mounted() {
    // 调用获取后端接口所有职位数据方法
    this.initPositions()
  },
  methods: {
    // 添加职位
    addPosition() {
      if (this.pos.name) {
        this.postRequest('/system/basic/pos/', this.pos).then(resp => {
          if (resp) {
            this.initPositions();
            this.pos.name = '';
          }
        })
      } else {
        this.$message.error('职位名称不能为空!');
      }
    },
    // 删除职位
    deletePosition(index, data) {
      this.$confirm('此操作将永久删除 [ ' + data.name + ' ] 职位,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('/system/basic/pos/' + data.id).then(resp => {
          if (resp) {
            this.initPositions();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 批量删除(取值）
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },
    // 批量删除职位
    batchDeletePosition() {
      this.$confirm('此操作将永久删除 [ ' + this.multipleSelection.length + ' ] 条职位,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = '?';
        this.multipleSelection.forEach(item => {
          ids += 'ids=' + item.id + '&';
        });
        this.deleteRequest('/system/basic/pos/' + ids).then(resp => {
          if (resp) {
            this.initPositions();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 显示编辑职位对话框
    showEditView(index, data) {
      // 回显数据,数据拷贝
      Object.assign(this.updatePos, data);
      // 更新日期由后端处理，这里不用传
      this.updatePos.createDate = '';
      // 显示编辑框
      this.dialogVisible = true;
    },
    // 关闭编辑职位对话框
    closeEditView() {
      this.$confirm('确认关闭？')
          .then(_ => {
            this.dialogVisible = false
            this.$message({
              type: 'info',
              message: '已取消编辑'
            });
          })
          .catch(_ => {
          });
    },
    // 编辑职位
    updatePosition() {
      this.putRequest('/system/basic/pos/', this.updatePos).then(resp => {
        if (resp) {
          // 刷新数据列表
          this.initPositions();
          // 关闭对话框
          this.dialogVisible = false;
        }
      })
    },
    // 获取后端接口所有职位数据
    initPositions() {
      this.getRequest('/system/basic/pos/').then(resp => {
        if (resp) {
          this.positions = resp;
        }
      })
    },
  }
}
</script>

<style scoped>
/*添加职位输入框*/
.addPosInput {
  width: 300px;
  margin-right: 8px;
}

/*所有数据表格*/
.posManageMain {
  margin-top: 10px;
}
</style>
