<template>
  <div>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="部门管理" name="DepManage">
        <DepManage></DepManage>
      </el-tab-pane>
      <el-tab-pane label="职位管理" name="PositionManage">
        <PositionManage></PositionManage>
      </el-tab-pane>
      <el-tab-pane label="职称管理" name="JobLevelManage">
        <JobLevelManage></JobLevelManage>
      </el-tab-pane>
      <el-tab-pane label="奖惩规则" name="EcManage">
        <EcManage></EcManage>
      </el-tab-pane>
      <el-tab-pane label="权限组" name="PermissManage">
        <PermissManage></PermissManage>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DepManage from "../../components/sys/basic/DepManage";
import PositionManage from "../../components/sys/basic/PositionManage";
import JobLevelManage from "../../components/sys/basic/JobLevelManage";
import EcManage from "../../components/sys/basic/EcManage";
import PermissManage from "../../components/sys/basic/PermissManage";

export default {
  name: "SysBasic",
  components: {
    DepManage,
    PositionManage,
    JobLevelManage,
    EcManage,
    PermissManage
  },
  data() {
    return {
      activeName: 'DepManage' // 激活项
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>