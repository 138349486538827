<template>
  <div>
    <div class="permissManageTool">
      <el-input v-model="role.name" placeholder="请输入角色英文名" size="small">
        <template slot="prepend">ROLE_</template>
      </el-input>
      <el-input v-model="role.nameZh" placeholder="请输入角色中文名" size="small" @keydown.enter.native="AddRole"></el-input>
      <el-button type="primary" icon="el-icon-plus" size="mini" @click="AddRole">添加角色</el-button>
    </div>
    <!-- 手风琴 -->
    <div class="permissManageMain">
      <!-- title:面板标题,name:唯一标志符 -->
      <el-collapse v-model="activeName" accordion @change="change">
        <el-collapse-item :title="r.nameZh" :name="r.id" v-for="(r,index) in roles" :key="index">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>可访问菜单</span>
              <el-button type="text" icon="el-icon-delete" style="float: right;padding: 3px 0;color: #f41f0a"
                         @click="deleteRole(r)">
              </el-button>
            </div>
            <div>
              <el-tree ref="treeRef" show-checkbox :data="allMenus" :props="defaultProps"
                       :default-checked-keys="roleMenus[r.id]"
                       node-key="id" :key="index"></el-tree>
              <div style="display: flex;justify-content: flex-end">
                <el-button size="mini" @click="cancelUpdate">取消修改</el-button>
                <el-button size="mini" type="primary" @click="updateRoleMenu(r.id,index)" v-loading="loading">确认修改
                </el-button>
              </div>
            </div>
          </el-card>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "PermissManage",
  data() {
    return {
      // 加载中
      loading: false,
      role: {
        name: '',
        nameZh: '',
      },
      roles: [],
      roleMenus: [[]],
      allMenus: [],
      // 树形控件
      defaultProps: {
        children: 'children',
        // 绑定数据 :label:'name'
        label: 'name'
      },
      selectedMenus: [],
      // 折叠面板 默认关闭
      activeName: -1
    }
  },
  mounted() {
    this.initRoles()
    this.initAllMenus()
    this.initSelectedMenus()
  },
  methods: {
    // 添加角色
    AddRole() {
      if (this.role.name && this.role.nameZh) {
        this.postRequest('/system/basic/permiss/role/', this.role).then(resp => {
          if (resp) {
            // 获取所有角色
            this.initRoles();
            this.role.name = '';
            this.role.nameZh = '';
          }
        })
      } else {
        this.$message.error('所有字段不能为空！')
      }
    },
    // 删除角色
    deleteRole(role) {
      this.$confirm('此操作将永久删除 [ ' + role.nameZh + ' ] 角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('/system/basic/permiss/role/' + role.id).then((resp) => {
          if (resp) {
            // 获取所有角色
            this.initRoles();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 修改角色菜单
    updateRoleMenu(rid, index) {
      this.loading = true;
      // 获取引用对象和索引
      let tree = this.$refs.treeRef[index];
      // 获取选中的节点数组,true 仅返回被选中的叶子节点的 keys
      let selectedKeys = tree.getCheckedKeys(true)
      let url = '/system/basic/permiss/?rid=' + rid;
      selectedKeys.forEach(key => {
        // 循环遍历出数组 id ，拼接在一起
        url += '&mids=' + key;
      })
      this.putRequest(url).then(resp => {
        if (resp) {
          // 获取所有选中的菜单
          this.initSelectedMenus();
          this.loading = false;
          // 关闭折叠面板
          this.activeName = -1;
        }
      })
    },
    // 取消修改
    cancelUpdate() {
      // 关闭折叠面板
      this.$confirm('确认关闭？')
          .then(_ => {
            this.activeName = -1;
            this.$message({
              type: 'info',
              message: '已取消编辑'
            });
          })
          .catch(_ => {
          });
    },
    // 手风琴点击事件
    change(rid) {
      // console.log(rid);
      if (rid) {
        // 调用获取所有菜单
        this.initAllMenus();
        // // 调用指定rid获取所有选中的菜单
        // this.initSelectedMenusByRid(rid);
      }
    },

    // 使用此方法容易出现还没获取到指定rid所有选中的菜单菜单就展开了的情况,不建议使用
    // // 获取指定rid所有选中的菜单
    // initSelectedMenusByRid(rid) {
    //   this.getRequest('/system/basic/permiss/mid/' + rid).then(resp => {
    //     if (resp) {
    //       this.selectedMenus = resp;
    //     }
    //   })
    // },

    // 获取所有选中的菜单
    initSelectedMenus() {
      this.getRequest('/system/basic/permiss/').then(resp => {
        if (resp) {
          for (let role of resp) {
            // console.log(role.id);
            this.getRequest('/system/basic/permiss/mid/' + role.id).then(resp => {
              if (resp) {
                this.roleMenus[role.id] = resp;
              }
            })
          }
        }
      })
      // console.log(this.roleMenus);
    },
    // 获取所有角色
    initRoles() {
      this.getRequest('/system/basic/permiss/').then(resp => {
        if (resp) {
          this.roles = resp;
        }
      })
    },
    // 获取所有菜单
    initAllMenus() {
      this.getRequest('/system/basic/permiss/menus/').then(resp => {
        if (resp) {
          this.allMenus = resp;
        }
      })
    }
  }
}
</script>

<style scoped>
.permissManageTool {
  display: flex;
  justify-content: flex-start;
}

.permissManageTool .el-input {
  width: 300px;
  margin-right: 6px;
}

.permissManageMain {
  margin-top: 10px;
  width: 700px;
}
</style>
