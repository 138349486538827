<template>
  <div>
    系统管理
  </div>
</template>

<script>
export default {
  name: "SysConfig"
}
</script>

<style scoped>

</style>