<template>
  <div>
    奖惩规则
  </div>
</template>

<script>
export default {
  name: "EcManage"
}
</script>

<style scoped>

</style>