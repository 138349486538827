import { render, staticRenderFns } from "./DepManage.vue?vue&type=template&id=3456dbdb&scoped=true&"
import script from "./DepManage.vue?vue&type=script&lang=js&"
export * from "./DepManage.vue?vue&type=script&lang=js&"
import style0 from "./DepManage.vue?vue&type=style&index=0&id=3456dbdb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3456dbdb",
  null
  
)

export default component.exports