<template>
  <div>
    <div>
      <el-input
          size="small"
          class="addJobLevelInput"
          placeholder="职称名称"
          suffix-icon="el-icon-plus"
          v-model="jobLevel.name">
      </el-input>
      <el-select size="small" class="selectTitleLevels" v-model="jobLevel.titleLevel" placeholder="职称等级">
        <el-option
            v-for="item in titleLevels"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      <el-button size="small" icon="el-icon-plus" type="primary" @click="addJobLevel">添加</el-button>
    </div>
    <div class="jobLevelManageMain">
      <el-table
          border
          stripe
          size="small"
          :data="jobLevels"
          style="width: 70%"
          @selection-change="handleSelectionChange">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column
            prop="id"
            label="编号"
            width="55">
        </el-table-column>
        <el-table-column
            prop="name"
            label="职称名称"
            width="120">
        </el-table-column>
        <el-table-column
            prop="titleLevel"
            label="职称等级"
            width="120">
        </el-table-column>
        <el-table-column
            prop="createDate"
            label="创建时间"
            width="150">
        </el-table-column>
        <el-table-column
            prop="enabled"
            label="是否启用"
            width="100">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.enabled">已启用</el-tag>
            <el-tag type="danger" v-else>未启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="showEditView(scope.$index, scope.row)">编辑
            </el-button>
            <el-button
                size="mini"
                type="danger"
                @click="deleteJobLevel(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- :disabled="this.multipleSelection.length===0" 当复选框没有一个被选中时隐藏 -->
    <el-button size="small" style="margin-top: 8px" type="danger"
               :disabled="this.multipleSelection.length===0"
               @click="batchDeleteJobLevel">批量删除
    </el-button>
    <!-- 编辑弹框 -->
    <el-dialog
        title="编辑职称"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="closeEditView">
      <table>
        <tr>
          <td>
            <el-tag>职称名称</el-tag>
          </td>
          <td>
            <el-input v-model="updateJob.name" size="small" style="margin-left: 6px"></el-input>
          </td>
        </tr>
        <tr>
          <td>
            <el-tag>职称等级</el-tag>
          </td>
          <td>
            <el-select size="small" v-model="updateJob.titleLevel" placeholder="职称等级"
                       style="margin-left: 6px;margin-right: 6px">
              <el-option
                  v-for="item in titleLevels"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td>
            <el-tag>是否启用</el-tag>
          </td>
          <td>
            <el-switch
                style="margin-left: 6px"
                v-model="updateJob.enabled"
                active-color="#13ce66"
                active-text="启用"
                inactive-text="未启用">
            </el-switch>
          </td>
        </tr>
      </table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateJobLevel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "JobLevelManage",
  data() {
    return {
      // 添加职称数据
      jobLevel: {
        name: '',
        titleLevel: ''
      },
      // 更新职称数据
      updateJob: {
        name: '',
        titleLevel: '',
        enabled: false,
      },
      jobLevels: [],
      // 弹出框是否显示
      dialogVisible: false,
      // 职称等级下拉框
      titleLevels: [
        '正高级',
        '副高级',
        '中级',
        '初级',
        '员级'
      ],
      // 批量删除勾选的对象
      multipleSelection: []
    }
  },
  mounted() {
    // 调用获取后端接口所有职称数据方法
    this.initJobLevels()
  },
  methods: {
    // 添加职称
    addJobLevel() {
      if (this.jobLevel.name && this.jobLevel.titleLevel) {
        this.postRequest('/system/basic/joblevel/', this.jobLevel).then(resp => {
          if (resp) {
            this.initJobLevels();

          }
        })
      } else {
        this.$message.error('职称名称不能为空!');
      }
    },
    // 删除职称
    deleteJobLevel(index, data) {
      this.$confirm('此操作将永久删除 [ ' + data.name + ' ] 职称,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRequest('/system/basic/joblevel/' + data.id).then(resp => {
          if (resp) {
            this.initJobLevels();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 批量删除(取值）
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },
    // 批量删除
    batchDeleteJobLevel() {
      this.$confirm('此操作将永久删除 [ ' + this.multipleSelection.length + ' ] 条职称,是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = '?';
        this.multipleSelection.forEach(item => {
          ids += 'ids=' + item.id + '&';
        });
        this.deleteRequest('/system/basic/joblevel/' + ids).then(resp => {
          if (resp) {
            this.initJobLevels();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 显示编辑职称对话框
    showEditView(index, data) {
      // 回显数据,数据拷贝
      Object.assign(this.updateJob, data);
      // 更新日期由后端处理，这里不用传
      this.updateJob.createDate = '';
      // 显示编辑框
      this.dialogVisible = true;
    },
    // 关闭编辑职称对话框
    closeEditView() {
      this.$confirm('确认关闭？')
          .then(_ => {
            this.dialogVisible = false
            this.$message({
              type: 'info',
              message: '已取消编辑'
            });
          })
          .catch(_ => {
          });
    },
    // 编辑职称
    updateJobLevel() {
      this.putRequest('/system/basic/joblevel/', this.updateJob).then(resp => {
        if (resp) {
          // 刷新数据列表
          this.initJobLevels();
          // 关闭对话框
          this.dialogVisible = false;
        }
      })
    },
    // 获取后端接口所有职称数据
    initJobLevels() {
      this.getRequest('/system/basic/joblevel/').then(resp => {
        if (resp) {
          this.jobLevels = resp;
          this.jobLevel.name = ''
          this.jobLevel.titleLevel = ''
        }
      })
    },
  }
}
</script>

<style scoped>
/*添加职称输入框*/
.addJobLevelInput {
  width: 300px;
}

/*职称等级下拉框*/
.selectTitleLevels {
  margin-left: 6px;
  margin-right: 6px
}

/*所有数据表格*/
.jobLevelManageMain {
  margin-top: 10px;
}
</style>