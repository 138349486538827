<template>
  <div>
    操作日志管理
  </div>
</template>

<script>
export default {
  name: "SysLog"
}
</script>

<style scoped>

</style>