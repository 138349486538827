<template>
  <div style="width: 500px">
    <el-input
        placeholder="请输入部门名称进行搜索..."
        prefix-icon="el-icon-search"
        v-model="filterText">
    </el-input>
    <!-- :expand-on-click-node="false" 点击小三角箭头才会展开
         :default-expand-all="false"	 设置默认不展开所有节点 -->
    <el-tree
        :data="deps"
        :props="defaultProps"
        :filter-node-method="filterNode"
        :default-expand-all="true"
        :expand-on-click-node="false"
        ref="tree">
      <!-- label: 'name' -->
      <!-- style="display: flex;justify-content: space-between;width: 100% 父容器宽度" 让添加和删除按键居右 -->
      <span class="custom-tree-node" slot-scope="{ node, data }"
            style="display: flex;justify-content: space-between;width: 100%">
        <span>{{ data.name }}</span>
        <span>
          <!-- showAddDep(data)  deleteDep(data)  data 后端传过来的完整的 json 对象 -->
          <el-button
              plain
              type="primary"
              size="mini"
              class="depBtn"
              @click="() => showAddDep(data)">
            添加部门
          </el-button>
          <el-button
              plain
              type="danger"
              size="mini"
              class="depBtn"
              @click="() => deleteDep(data)">
            删除部门
          </el-button>
        </span>
      </span>
    </el-tree>
    <!-- 对话弹框 -->
    <el-dialog
        title="添加部门"
        :visible.sync="dialogVisible"
        :before-close="closeDepView"
        width="30%">
      <div>
        <table>
          <tr>
            <td>
              <el-tag>上级部门</el-tag>
            </td>
            <td>{{ pname }}</td>
          </tr>
          <tr>
            <td>
              <el-tag>部门名称</el-tag>
            </td>
            <td>
              <el-input v-model="dep.name" placeholder="请输入部门名称..." size="small"></el-input>
            </td>
          </tr>
        </table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <!-- 确定添加按钮绑定事件 @click="addDep" -->
        <el-button type="primary" @click="addDep">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "DepManage",
  data() {
    return {
      // 搜索(框架数据)
      filterText: '',
      // 所有部门整个数组
      deps: [],
      // 关联子部门
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      dialogVisible: false,
      // 添加部门数据
      dep: {
        name: '',
        parentId: -1,
        isParent: ''
      },
      // 上级部门名称
      pname: ''
    }
  },
  watch: {
    // 观察者事件，监控输入框的值(框架方法）
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted() {
    // 调用获取所有部门方法
    this.initDeps();
  },
  methods: {
    // 添加部门弹框
    showAddDep(data) {
      // console.log(data);
      this.dep.parentId = data.id;
      this.pname = data.name;
      this.dialogVisible = true;
    },
    // 关闭弹窗
    closeDepView() {
      this.$confirm('确认关闭？')
          .then(_ => {
            this.dialogVisible = false
            this.$message({
              type: 'info',
              message: '已取消添加'
            });
          })
          .catch(_ => {
          });
    },
    // 添加部门
    addDep() {
      this.postRequest('/system/basic/department/', this.dep).then(resp => {
        if (resp) {
          // console.log(resp);
          // 关闭对话框
          this.dialogVisible = false;
          // 手动插入部门 显示添加后的数据
          this.addDepToDeps(this.deps, resp.obj);
          // 调用初始化方法 清空数据
          this.initDep();
        }
      })
    },
    // 递归查询所有部门信息，deps 查询到的整个数组，dep 要添加的部门(添加部门调用的方法)
    addDepToDeps(deps, dep) {
      // console.log(dep);
      for (let i = 0; i < deps.length; i++) {
        // 父部门
        let d = deps[i];
        if (d.id === dep.parentId) {
          // console.log(dep.parentId);
          // 把 dep 加为 d 的子部门
          d.children = d.children.concat(dep);
          if (d.children.length > 0) {
            d.isParent = true;
          }
          return;
        } else {
          // 递归调用此方法,以查询结果为条件,继续查询子部门
          this.addDepToDeps(d.children, dep);
        }
      }
    },
    // 添加完部门 初始化 清空数据
    initDep() {
      this.dep = {
        name: '',
        parentId: -1
      }
      this.pname = '';
    },
    // 删除部门
    deleteDep(data) {
      // console.log(data);
      if (data.isParent) {
        this.$message.error('父部门删除失败!');
      } else {
        this.$confirm('此操作将永久删除 [ ' + data.name + ' ] 部门, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deleteRequest('/system/basic/department/' + data.id).then(resp => {
            if (resp) {
              this.removeDepFromDeps(null, this.deps, data.id)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    // 递归查询所有部门信息，deps 查询到的整个数组，id 要删除的部门id(删除部门调用的方法)
    removeDepFromDeps(p, deps, id) {
      // console.log(deps);
      for (let i = 0; i < deps.length; i++) {
        let d = deps[i];
        if (d.id === id) {
          // 删除 deps 中第 i 个部门
          deps.splice(i, 1);
          if (deps.length === 0) {
            p.isParent = false
          }
          return;
        } else {
          this.removeDepFromDeps(d, d.children, id)
        }
      }
    },
    // 获取所有部门
    initDeps() {
      this.getRequest('/system/basic/department/').then(resp => {
        if (resp) {
          this.deps = resp
        }
      })
    },
    // 搜索事件(框架方法）
    // data 整行数据
    filterNode(value, data) {
      // true 节点可以展示，false 节点隐藏
      if (!value) return true;
      // label: 'name'
      return data.name.indexOf(value) !== -1;
    }
  }
}
</script>

<style scoped>
.depBtn {
  padding: 2px;
}

</style>